.fade-in {
    animation: fade-in 1s ease-in;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}